import { ImageCardData } from '../interface/imageCardData'

const ImageCardUniqueTabletDesktop = ({ unique }: { unique: ImageCardData }) => {
  return (
    <div
      className={`w-full flex flex-row gap-[16px] xl:gap-[28px] justify-start items-start rounded-[10px] bg-white overflow-hidden`}
    >
      <img
        className={`w-[146px] xl:w-[217px] object-cover ` + unique.heightClassNameTabletDesktop}
        alt={`unique_image`}
        src={unique.imageSrc}
      />
      <div className={`flex flex-col justify-start items-start gap-[8px] xl:gap-[24px] py-[20px] xl:py-[40px]`}>
        <div className={`prose-h8 xl:prose-h7 text-black hidden md:max-xl:block`}>{unique.title.tablet}</div>
        <div className={`prose-h8 xl:prose-h7 text-black hidden xl:block`}>{unique.title.desktop}</div>
        <div className={`prose-p4 xl:prose-p3 text-gray800 whitespace-pre-line hidden md:max-xl:block`}>
          {unique.content.tablet}
        </div>
        <div className={`prose-p4 xl:prose-p3 text-gray800 whitespace-pre-line hidden xl:block`}>
          {unique.content.desktop}
        </div>
      </div>
    </div>
  )
}

export default ImageCardUniqueTabletDesktop
