import HONORS from '../../data/Honors'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const Honors = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>'
    },
  }

  return (
    <div
      className={`flex flex-col gap-[40px] justify-center items-center max-w-[333px] md:max-w-[624px] xl:max-w-[1200px] mx-auto`}
    >
      <div className={`flex flex-col gap-[4px] justify-center items-center`}>
        <div
          className={`prose-p3 md:prose-p2 md:font-semibold xl:prose-h8 font-medium text-blue700`}
        >{`Honors and Innovations`}</div>
        <div className={`md:hidden prose-h8 text-black whitespace-pre-line text-center`}>
          {`Explore the patents and\naccolades that underscore\nViasonic's commitment to\ninnovation and excellence\nin the global arena.`}
        </div>
        <div className={`hidden md:max-xl:block prose-h6 text-black whitespace-pre text-center`}>
          {`Explore the patents and accolades that underscore\nViasonic's commitment to innovation\nand excellence in the global arena.`}
        </div>
        <div className={`hidden xl:block prose-h4 text-black whitespace-pre-line text-center`}>
          {`Explore the patents and accolades that\nunderscore Viasonic's commitment to innovation\nand excellence in the global arena.`}
        </div>
      </div>

      <div className={`w-full min-h-[390px] md:hidden`}>
        <SwiperWrapper>
          <Swiper pagination={pagination} modules={[Pagination]} allowTouchMove={false}>
            {[[HONORS[0], HONORS[1]], [HONORS[2], HONORS[3]], [HONORS[4], HONORS[5]], [HONORS[6]]].map((d, i) => {
              return (
                <SwiperSlide key={i}>
                  <HonorPage honors={d} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      </div>

      <div className={`w-full min-h-[452px] hidden md:max-xl:block`}>
        <SwiperWrapper>
          <Swiper pagination={pagination} modules={[Pagination]} allowTouchMove={false}>
            {[[HONORS[0], HONORS[1], HONORS[2]], [HONORS[3], HONORS[4], HONORS[5]], [HONORS[6]]].map((d, i) => {
              return (
                <SwiperSlide key={i}>
                  <HonorPage honors={d} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      </div>

      <div className={`w-full min-h-[575px] hidden xl:block`}>
        <SwiperWrapper>
          <Swiper pagination={pagination} modules={[Pagination]} allowTouchMove={false}>
            {[
              [HONORS[0], HONORS[1], HONORS[2], HONORS[3]],
              [HONORS[4], HONORS[5], HONORS[6]],
            ].map((d, i) => {
              return (
                <SwiperSlide key={i}>
                  <HonorPage honors={d} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      </div>
    </div>
  )
}

const SwiperWrapper = styled(Box)({
  '& .swiper': {
    width: '100%',
    height: '100%',
  },

  '& .swiper-slide': {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .swiper-pagination': {
    position: 'unset',
    display: 'flex',
    justifyContent: 'center',
    gap: '28px',
    paddingTop: '40px',
    '@media (min-width: 768px)': {
      gap: '40px',
      paddingTop: '16px',
    },
    '@media (min-width: 1280px)': {
      paddingTop: '44px',
    },
  },

  '& .swiper-pagination-bullet': {
    width: '24px',
    height: '24px',
    color: '#A7A7A7',
    opacity: '1',
    background: '#fff',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    '@media (min-width: 768px)': {
      fontSize: '20px',
      letterSpacing: '0.5px',
    },
  },

  '& .swiper-pagination-bullet-active': {
    color: '#0B15FF',
    background: '#F2F5FF',
  },
})

const HonorPage = ({ honors }: { honors: HonorCardProps[] }) => {
  return (
    <div
      className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[16px] justify-center items-start place-content-center p-1`}
    >
      {honors.map((honor, i) => {
        return (
          <HonorCard
            key={i}
            imgSrc={honor.imgSrc}
            title={honor.title}
            content={honor.content}
            corporation={honor.corporation}
          />
        )
      })}
    </div>
  )
}

interface HonorCardProps {
  imgSrc: string
  title: string
  content: string
  corporation: string
}
const HonorCard = (props: HonorCardProps) => {
  return (
    <div className={`flex flex-col gap-[20px] md:gap-[12px] justify-center items-start`}>
      <img
        className={`shadow-[0px_0px_4px_0px_rgba(0,0,0,0.12)] h-[222px] md:h-[264px] xl:h-[372px]`}
        alt="honor_image"
        src={props.imgSrc}
      />
      <div className={`w-4/5 flex flex-col gap-[8px] justify-center items-start`}>
        <div className={`prose-p3 xl:prose-h8 text-black font-semibold`}>{props.title}</div>
        <div className={`prose-p4 md:prose-p3 xl:prose-p2 text-gray800 whitespace-pre-line`}>{props.content}</div>
        <div className={`prose-p5 md:prose-p4 xl:prose-p3 text-gray500`}>{props.corporation}</div>
      </div>
    </div>
  )
}

export default Honors
