import FOOTER from '../../data/footer'
import split from '../../utils/arrayUtils'

const CompanyInformationTabletDesktop = () => {
  const onClickExternalUrl = (url: string) => window.open(url)

  return (
    <div className={`flex flex-col gap-[8px] justify-center items-start max-md:hidden`}>
      {split(FOOTER.info, 3).map((infos, i) => {
        return (
          <div key={i} className={`flex flex-row justify-start items-center flex-wrap  gap-x-[24px] gap-y-[5px]`}>
            {infos.map((info, i) => {
              return (
                <div key={i} className={`flex flex-row gap-[8px]`}>
                  <div className={`text-gray500`}>{info.title}</div>
                  <div className={`leading-[24px]`}>{info.content}</div>
                  {info.title === 'Business Number' && (
                    <div
                      className={`cursor-pointer font-semibold max-xl:hidden`}
                      onClick={() => onClickExternalUrl(FOOTER.url.businessInformation)}
                    >
                      사업자정보확인
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default CompanyInformationTabletDesktop
