import React, { ReactNode } from 'react'

const AppLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main
      className={
        `min-w-[375px] w-full relative min-h-screen overflow-hidden mx-auto flex flex-col items-center bg-white` +
        ` scrollbar-hide md:scrollbar-default font-pretendard`
      }
    >
      {children}
    </main>
  )
}

export default AppLayout
