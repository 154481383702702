import FeatureImage1 from '../assets/images/technology/feature/feature_1.png'
import FeatureImage2 from '../assets/images/technology/feature/feature_2.png'
import FeatureImage3 from '../assets/images/technology/feature/feature_3.png'
import { ImageCardData } from '../interface/imageCardData'

const FEATURES = [
  {
    imageSrc: FeatureImage1,
    heightClassNameTabletDesktop: `md:h-[243px] xl:h-[455px]`,
    title: {
      mobile: `Advanced Sound\nPropagation Algorithms`,
      tablet: `Advanced Sound\nPropagation\nAlgorithms`,
      desktop: `Advanced Sound\nPropagation Algorithms`,
    },
    content: {
      mobile: `ViaNoise incorporates state-of-the-art\nalgorithms for in-depth analysis of sound\npropagation in various environments.`,
      tablet: `ViaNoise incorporates state-\nof-the-art algorithms for in-\ndepth analysis of sound\npropagation in various\nenvironments.`,
      desktop: `ViaNoise incorporates state-of-the-art algorithms\nfor in-depth analysis of sound propagation in\nvarious environments.`,
    },
  },
  {
    imageSrc: FeatureImage2,
    heightClassNameTabletDesktop: `md:h-[152px] xl:h-[273px]`,
    title: {
      mobile: `Comprehensive Noise\nMitigation Solutions`,
      tablet: `Comprehensive\nNoise Mitigation\nSolutions`,
      desktop: `Comprehensive Noise\nMitigation Solutions`,
    },
    content: {
      mobile: `The software offers a range of\nstrategies and tools for effective noise\nreduction, including sound barriers and\narchitectural acoustics.`,
      tablet: `The software offers a range\nof strategies and tools for\neffective noise reduction,\nincluding sound barriers and\narchitectural acoustics.`,
      desktop: `The software offers a range of strategies and\ntools for effective noise reduction, including sound\nbarriers and architectural acoustics.`,
    },
  },
  {
    imageSrc: FeatureImage3,
    heightClassNameTabletDesktop: `md:h-[191px] xl:h-[354px]`,
    title: {
      mobile: `Enhanced Data\nVisualization Tools`,
      tablet: `Enhanced Data\nVisualization Tools`,
      desktop: `Enhanced Data\nVisualization Tools`,
    },
    content: {
      mobile: `ViaNoise's advanced visualization\ncapabilities make it easier to\nunderstand and communicate complex\nacoustic data.`,
      tablet: `ViaNoise's advanced\nvisualization capabilities\nmake it easier to understand\nand communicate complex\nacoustic data.`,
      desktop: `ViaNoise's advanced visualization capabilities\nmake it easier to understand and communicate\ncomplex acoustic data.`,
    },
  },
] as ImageCardData[]

export default FEATURES
