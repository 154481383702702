import { MutableRefObject, useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import HamburgerIcon from '../../assets/icons/hamberger.svg'
import CloseIcon from '../../assets/icons/close.svg'

const TAB_NAMES = ['Viasonic', 'Product', 'Service', 'News', 'Contact']

const Hamburger = ({ tabRef }: { tabRef: MutableRefObject<HTMLDivElement[]> }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClickTab = (index: number) => {
    tabRef.current[index].scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
    setIsOpen(false)
  }

  return (
    <div className={`md:hidden`}>
      <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => setIsOpen(true)}>
        <img alt={'hamburger'} src={HamburgerIcon} />
      </IconButton>
      <Drawer
        className={`w-full px-[44px] py-[60px] flex flex-row gap-[24px]`}
        PaperProps={{ sx: { width: '100%' } }}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className={`w-full px-[44px] py-[60px] flex flex-col gap-[24px] justify-start items-start`}>
          {TAB_NAMES.map((title, index) => {
            return (
              <div key={index} className={`prose-h8 font-normal`} onClick={() => onClickTab(index)}>
                {title}
              </div>
            )
          })}
        </div>
        <div className={'fixed top-0 right-0 p-[24px]'} onClick={() => setIsOpen(false)}>
          <img alt={'close'} src={CloseIcon} />
        </div>
      </Drawer>
    </div>
  )
}

export default Hamburger
