import { ReactNode } from 'react'

const SectionLayout = ({ className = '', children }: SectionLayoutProps) => {
  return <section className={`w-full flex justify-center items-center ` + className}>{children}</section>
}

interface SectionLayoutProps {
  className?: string
  children: ReactNode
}

export default SectionLayout
