const FOOTER = {
  url: {
    privacyPolicy: '',
    serviceTerms: '',
    businessInformation: '',
    youtube: '',
    instagram: '',
  },
  info: [
    {
      title: 'Company',
      content: 'Viasonic Co.Ltd',
    },
    {
      title: 'Email',
      content: 'info@viasonic.co.kr',
    },
    {
      title: 'Tell',
      content: '031-8098-6696',
    },
    {
      title: 'Fax',
      content: '031-8098-6696',
    },
    {
      title: 'Address',
      content: `1105, 178, Gyeonggi-daero,Osan-si, Gyeonggi-do, KOREA`,
    },
  ] as FooterInformation[],
}

interface FooterInformation {
  title: string
  content: string
}

export default FOOTER
