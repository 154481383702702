import HistoryBackground from '../../assets/images/introduce/history/history.svg'
import React, { useEffect, useState } from 'react'

const HistoryTabletDesktop = () => {
  const [selected, setSelected] = useState(0)

  return (
    <div
      className={`relative hidden md:flex max-w-[864px] xl:max-w-[1197px] mx-auto flex-row justify-center items-center h-[652px] xl:h-[960px]`}
    >
      <div
        className={
          `relative flex flex-col justify-center items-start px-[42px] py-[44px] w-[331px] h-[468px]` +
          ` xl:w-[480px] xl:h-[570px]`
        }
      >
        <img
          className={`absolute z-10 top-0 left-0 rounded-[10px] w-full h-full object-cover`}
          alt={`history_background`}
          src={HistoryBackground}
        />
        <div className={`flex flex-col gap-[74px] h-full justify-between items-start`}>
          <div className={`prose-h5 xl:prose-h3 z-20 text-white`}>{`History`}</div>
          <div className={`flex z-20 flex-col gap-[11px] justify-center items-start prose-h7 xl:prose-h6 text-white`}>
            {[`2023`, `2022`, `~2021`].map((year, i) => {
              return (
                <div
                  key={i}
                  className={(selected === i ? `opacity-100` : `opacity-50`) + ` cursor-pointer`}
                  onClick={() => setSelected(i)}
                >
                  {year}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className={`w-[371px] h-[370px] xl:w-[786px] xl:h-[392px] ml-[-40px] xl:ml-[-75px] z-20`}>
        <div
          className={
            `w-full h-full p-[32px] xl:px-[80px] xl:py-[44px] flex flex-col gap-[16px] justify-start items-start bg-white` +
            ` rounded-[8px] shadow-[0px_0px_4px_0px_rgba(0,0,0,0.12)]`
          }
        >
          <CardRenderer selected={selected} />
        </div>
      </div>
    </div>
  )
}

const CardRenderer = ({ selected }: { selected: number }) => {
  const [fade, setFade] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setFade('end')
    }, 100)

    return () => {
      setFade('')
    }
  }, [selected])

  return (
    <div className={'start ' + fade}>
      {[<Card2023 key={0} />, <Card2022 key={1} />, <Card2021 key={2} />][selected]}
    </div>
  )
}

const Card2023 = () => {
  return (
    <>
      <div className={`prose-h7 xl:prose-h5 text-blue700 mb-[20px] xl:mb-[24px]`}>{`2023`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`November`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li
              className={`text-gray500`}
            >{`Selected for the Ministry of SMEs and Startups Internal Corporate Venture Fostering Program (5th Generation)`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`October`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li className={`text-gray500`}>
              {`Selected for the Korea Expressway Corporation Internal Corporate Venture (5th Generation)`}
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const Card2022 = () => {
  return (
    <>
      <div className={`prose-h7 xl:prose-h5 mb-[20px] xl:mb-[24px]`}>{`2022`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`June 2022`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li
              className={`text-gray500`}
            >{`Awarded the Korea Expressway Corporation Excellent Research Project Commendation`}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

const Card2021 = () => {
  return (
    <>
      <div className={`prose-h7 xl:prose-h5 mb-[20px] xl:mb-[24px]`}>{`~2021`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`November 2016`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li
              className={`text-gray500`}
            >{`Development of a road traffic noise acoustic power calculation program`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`November 2015`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li className={`text-gray500`}>{`Development of a 3D noise prediction program for highways`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p3 xl:prose-h8 xl:font-normal`}>
          <div className={`text-black`}>{`June 2015`}</div>
          <ul className={`list-disc list-inside xl:prose-p3`}>
            <li className={`text-gray500`}>{`Awarded the Minister of Environment Commendation on Environment Day.`}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HistoryTabletDesktop
