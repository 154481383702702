import FEATURES from '../../data/features'
import ImageCardFeatureTabletDesktop from '../ImageCardFeatureTabletDesktop'

const FeatureTabletDesktop = () => {
  return (
    <div
      className={`hidden md:flex flex-col gap-[24px] xl:gap-[32px] justify-center items-start mx-auto max-w-[656px] xl:max-w-[1200px]`}
    >
      <div className={`prose-p2 xl:prose-h8 font-semibold text-blue700`}>{`Key Features`}</div>
      <div className={`flex flex-row justify-center items-start gap-[16px] xl:gap-[28px]`}>
        {FEATURES.map((feature, i) => {
          return <ImageCardFeatureTabletDesktop key={i} feature={feature} />
        })}
      </div>
    </div>
  )
}

export default FeatureTabletDesktop
