import LogoBrandVertical from '../../assets/logo_brand_vertical.svg'
import CompanyInformationMobile from './CompanyInformationMobile'
import CompanyInformationTabletDesktop from './CompanyInformationTabletDesktop'
import SnsCopyright from './SnsCopyright'
import FooterLayout from './FooterLayout'

const Footer = () => {
  // TODO: 실제 링크 추가되면 onClickPolicy 및 주석 제거
  // const onClickExternalUrl = (url: string) => {
  //   window.open(url)
  //   alert('준비중입니다.')
  // }

  const onClickPolicy = () => {
    alert('준비중입니다.')
  }

  return (
    <FooterLayout>
      <img
        className={`w-[80px] md:max-w-[130px] md:w-[10em] xl:w-[150px] xl:max-w-none h-auto`}
        alt={`logo_brand_vertical`}
        src={LogoBrandVertical}
      />
      <div
        className={`mt-[24px] flex flex-col gap-[20px] md:gap-[16px] md:max-xl:w-[629px] xl:gap-[20px] prose-p5 md:prose-p3 leading-[16px] text-black`}
      >
        <div className={`flex flex-col gap-[12px] justify-center items-start`}>
          <div className={`flex flex-row gap-[8px] md:gap-[16px] md:leading-[24px] justify-center items-start`}>
            <div className={`font-semibold cursor-pointer`} onClick={onClickPolicy}>
              개인정보 처리방침
            </div>
            <div className={`text-gray200 leading-[13px] md:leading-[20px]`}>|</div>
            <div className={`cursor-pointer`} onClick={onClickPolicy}>
              이용약관
            </div>
          </div>
          <CompanyInformationMobile />
          <CompanyInformationTabletDesktop />
        </div>
        <SnsCopyright />
      </div>
    </FooterLayout>
  )
}

export default Footer
