import SectionLayout from '../SectionLayout'
import React, { useEffect, useRef, useState } from 'react'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PrevIcon from '../../assets/icons/prev.svg'
import NextIcon from '../../assets/icons/next.svg'
import ProductMobile from './ProductMobile'
import ProductTabletDesktop from './ProductTabletDesktop'
import FeaturesMobile from './FeaturesMobile'
import FeatureTabletDesktop from './FeatureTabletDesktop'
import UniqueMobile from './UniqueMobile'
import UniqueTabletDesktop from './UniqueTabletDesktop'
import LogoBrandHorizontal from '../../assets/logo_brand_horizontal.svg'
import { Box } from '@mui/material'
import { Navigation } from 'swiper/modules'

const ProductSection = () => {
  const TabNames = ['Product', 'Key Features', 'Unique Differentiators', 'Contact us']
  const [selected, setSelected] = useState(0)
  const [swiper, setSwiper] = useState<SwiperCore>()
  const ref = useRef<HTMLDivElement>(null)
  const handleOnClickContactUs = () => {
    window.open('mailto:info@viasonic.co.kr')
  }

  useEffect(() => {
    if (ref.current) {
      if (selected >= 2) {
        ref.current.scrollTo({ left: ref.current.scrollWidth, behavior: 'smooth' })
      } else {
        ref.current.scrollTo({ left: 0, behavior: 'smooth' })
      }
    }
  }, [selected])

  return (
    <SectionLayout className={`px-[20px] py-[48px] md:py-[87px] xl:py-[180px] bg-blue50`}>
      <div
        className={`relative w-full max-w-[1680px] flex flex-col gap-[40px] justify-center items-center xl:h-[720px]`}
      >
        <div
          className={`w-full flex gap-[12px] h-[36px] justify-start flex-nowrap overflow-x-auto scrollbar-hide prose-p3 text-gray500 md:hidden mx-[-20px] px-[20px]`}
          style={{ width: 'calc(100% + 40px)' }}
          ref={ref}
        >
          {TabNames.map((name, i) => {
            return <Tab key={i} selected={selected} index={i} name={name} swiper={swiper} />
          })}
        </div>

        <div className={`w-full max-w-[1680px] max-h-[640px]`}>
          <Swiper
            spaceBetween={40}
            slidesPerView={1}
            modules={[Navigation]}
            onSlideChange={(swiper: SwiperCore) => {
              const slideIndex = swiper.activeIndex
              setSelected(slideIndex)
            }}
            navigation={{
              nextEl: '.product-arrow-right',
              prevEl: '.product-arrow-left',
            }}
            onSwiper={setSwiper}
          >
            <SwiperSlide className={``}>
              <ProductMobile />
              <ProductTabletDesktop />
            </SwiperSlide>
            <SwiperSlide className={``}>
              <FeaturesMobile />
              <FeatureTabletDesktop />
            </SwiperSlide>
            <SwiperSlide className={``}>
              <UniqueMobile />
              <UniqueTabletDesktop />
            </SwiperSlide>
            <SwiperSlide className={``}>
              <div className={`h-[594px]`}>
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <img className={`w-[211px] md:w-[264px]`} alt={`logo_brand_horizontal`} src={LogoBrandHorizontal} />
                  <div
                    className={
                      `text-center text-gray800 text-xl font-semibold leading-normal tracking-wide pt-5 pb-8` +
                      ` md:prose-h6 md:font-medium md:pt-[28px] md:pb-[48px]` +
                      ` xl:prose-p1 xl:text-[36px] xl:pt-[40px] xl:pb-[80px]`
                    }
                  >
                    If you have any questions,
                    <br />
                    please contact us.
                  </div>
                  <div
                    className={
                      `w-[161px] h-[54px] px-10 py-3 bg-blue700 rounded-2xl justify-center items-center gap-2.5 inline-flex cursor-pointer` +
                      ` md:w-[199px] md:h-[64px] md:py-[20px] md:px-[64px] whitespace-nowrap` +
                      ` xl:w-[289px] xl:h-[78px] whitespace-nowrap`
                    }
                    onClick={handleOnClickContactUs}
                  >
                    <div className="text-white text-base font-semibold leading-snug md:prose-h8 xl:prose-h5 xl:font-medium">
                      Contact us
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Box
            sx={{
              '.product-arrow-left, .product-arrow-right': {
                display: 'none',
                cursor: 'pointer',
                width: '60px',
                '@media (min-width: 768px)': {
                  display: 'block',
                  position: 'absolute',
                  top: '50%',
                  zIndex: 1,
                  transform: 'translateY(-50%)',
                },
              },
              '.swiper-button-disabled': {
                display: 'none',
              },
            }}
          >
            <img className="product-arrow-left left-0 md:left-[4px]" src={PrevIcon} alt="previous slide" />
            <img className="product-arrow-right right-0 md:right-[4px]" src={NextIcon} alt="next slide" />
          </Box>
        </div>
      </div>
    </SectionLayout>
  )
}

const Tab = ({
  selected,
  index,
  name,
  swiper,
}: {
  selected: number
  index: number
  name: string
  swiper: SwiperCore
}) => {
  return (
    <div
      className={
        (selected === index ? `font-medium text-blue700 border-b border-blue700` : ``) +
        ` flex-none cursor-pointer flex justify-center items-center`
      }
      onClick={() => swiper.slideTo(index)}
    >
      <div>{name}</div>
    </div>
  )
}

export default ProductSection
