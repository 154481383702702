import { ReactNode } from 'react'

const FooterLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className={`w-full flex justify-center items-center border-t-gray100 border-t bg-white`}>
      <div
        className={
          `w-full max-w-[375px] px-[20px] py-[36px] flex flex-col justify-center items-start ` +
          `md:justify-between md:items-center md:gap-[84px] md:flex-row md:max-w-[1026px] md:px-[81px] md:pt-[48px] md:pb-[39px] ` +
          `xl:gap-[0] xl:max-w-[1197px] xl:px-[76px] xl:pt-[44px] xl:pb-[75px]`
        }
      >
        {children}
      </div>
    </div>
  )
}

export default FooterLayout
