import SectionLayout from './SectionLayout'
import BannerMobile from '../assets/images/banner/banner_mobile.png'
import BannerTablet from '../assets/images/banner/banner_tablet.png'
import BannerDesktop from '../assets/images/banner/banner_desktop.png'
import ScrollDownIcon from '../assets/icons/scroll_down.svg'
const BannerSection = () => {
  return (
    <SectionLayout className={`pt-[24px] pb-[31px] px-[20px]`}>
      <div className={`w-full max-w-[1561px] relative`}>
        <div className={``}>
          <img className={`md:hidden w-full`} alt={`banner_mobile`} src={BannerMobile} />
          <img className={`hidden w-full md:max-xl:block`} alt={`banner_tablet`} src={BannerTablet} />
          <img className={`hidden w-full xl:block`} alt={`banner_desktop`} src={BannerDesktop} />
        </div>
        <div
          className={
            `absolute bottom-[48px] px-[24px] flex flex-col gap-[4px] justify-center items-start` +
            ` md:inset-y-1/2 md:pl-[60px] md:gap-[12px]`
          }
        >
          <div className={`prose-p3 md:prose-h8 xl:prose-h7 font-medium text-gray500 xl:text-blue700`}>Viasonic</div>
          <div
            className={`prose-h7 whitespace-pre-wrap md:prose-h4 xl:prose-h1 xl:font-semibold text-black whitespace-pre`}
          >
            <span className={`text-blue700 xl:font-bold`}>{`Discovering`}</span>
            {` the\nScience of `}
            <span className={`text-blue700 xl:font-bold`}>{`Acoustics\nand Noise`}</span>
            {`, Redefining\nEveryday Sounds.`}
          </div>
        </div>
        <div
          className={`absolute hidden bottom-[28px] inset-x-0 md:flex flex-col gap-[4px] justify-center items-center`}
        >
          <div className={`prose-p3 `}>Scroll</div>
          <img className={`px-[10px] py-[13px]`} alt={`scroll_down`} src={ScrollDownIcon} />
        </div>
      </div>
    </SectionLayout>
  )
}

export default BannerSection
