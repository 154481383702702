import UNIQUE from '../../data/unique'
import ImageCardUniqueTabletDesktop from '../ImageCardUniqueTabletDesktop'

const UniqueTabletDesktop = () => {
  return (
    <div
      className={`hidden md:flex flex-col gap-[24px] xl:gap-[32px] justify-center items-start mx-auto max-w-[656px] xl:max-w-[1200px]`}
    >
      <div className={`prose-p2 xl:prose-h8 font-semibold text-blue700`}>{`Unique Differentiators`}</div>
      <div
        className={
          `w-full flex flex-col gap-[12px] justify-center items-center` +
          ` xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:gap-[24px]`
        }
      >
        {UNIQUE.map((unique, i) => {
          return <ImageCardUniqueTabletDesktop key={i} unique={unique} />
        })}
      </div>
    </div>
  )
}

export default UniqueTabletDesktop
