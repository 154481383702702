interface ImageCardMobileProps {
  title: string
  imageSrc: string
  content: string
  index: number
}

const ImageCardMobile = (props: ImageCardMobileProps) => {
  return (
    <div className={`w-full flex flex-col gap-[12px] justify-center items-start`}>
      <div className={`flex flex-col gap-[24px] justify-center items-start`}>
        <div className={`prose-h8 text-black whitespace-pre-line h-[47px]`}>{props.title}</div>
        <div className={`w-full h-[397px]`}>
          <img className={`h-full rounded-[16px] object-cover`} alt={`${props.title}_image`} src={props.imageSrc} />
        </div>
      </div>
      <div className={`prose-p3 text-gray800 whitespace-pre-line h-[88px]`}>{props.content}</div>
    </div>
  )
}

export default ImageCardMobile
