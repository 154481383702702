import HonorImage1 from '../assets/images/introduce/honor/hornor_1.jpg'
import HonorImage2 from '../assets/images/introduce/honor/hornor_2.jpg'
import HonorImage3 from '../assets/images/introduce/honor/hornor_3.jpg'
import HonorImage4 from '../assets/images/introduce/honor/hornor_4.jpg'
import HonorImage5 from '../assets/images/introduce/honor/hornor_5.jpg'
import HonorImage6 from '../assets/images/introduce/honor/hornor_6.jpg'
import HonorImage7 from '../assets/images/introduce/honor/hornor_7.jpg'

const HONORS = [
  {
    imgSrc: HonorImage1,
    title: `Patent 2018`,
    content: `Tone burst ultrasonic signal Animal accident prevention device used`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage2,
    title: `Patent 2016`,
    content: `Soundproof tunnel for easy escape`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage3,
    title: `Patent 2015`,
    content: `soundproof tunnel`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage4,
    title: `Patent 2016`,
    content: `noise reduction device Noise reduction performance evaluation method`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage5,
    title: `Patent 2018`,
    content: `Tone burst ultrasonic signal Animal accident prevention device used`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage6,
    title: `Patent 2018`,
    content: `Tone burst ultrasonic signal Animal accident prevention device used`,
    corporation: `Korea Expressway Corporation`,
  },
  {
    imgSrc: HonorImage7,
    title: `Patent 2018`,
    content: `Tone burst ultrasonic signal Animal accident prevention device used`,
    corporation: `Korea Expressway Corporation`,
  },
]

export default HONORS
