import TempPartner from '../../assets/images/partner/temp_partner.svg'

const Partners = () => {
  return (
    <div
      className={`flex flex-col gap-[40px] md:gap-[52px] xl:gap-[92px] justify-center items-center max-w-[333px] md:max-w-[692px] xl:max-w-[1200px] mx-auto`}
    >
      <div className={`flex flex-col gap-[4px] md:gap-[16px] justify-center items-center`}>
        <div
          className={`prose-p3 md:prose-p2 md:font-semibold xl:prose-h8 font-medium text-blue700`}
        >{`Our Partners`}</div>
        <div className={`md:hidden prose-h8 text-black text-center`}>
          {`Explore the patents and accolades that underscore Viasonic's commitment to innovation and excellence in the global arena.`}
        </div>
        <div className={`hidden md:max-xl:block prose-h6 text-black text-center whitespace-pre-line`}>
          {`Explore the patents and accolades that underscore\nViasonic's commitment to innovation\nand excellence in the global arena.`}
        </div>
        <div className={`hidden xl:block prose-h4 text-black text-center whitespace-pre-line`}>
          {`Explore the patents and accolades that\nunderscore Viasonic's commitment to innovation\nand excellence in the global arena.`}
        </div>
      </div>
      <div
        className={`md:hidden grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[16px] justify-center items-start place-content-center`}
      >
        {Array.from(Array(14).keys()).map((k) => {
          return <TempPartnerCard key={k} />
        })}
      </div>
      <div className={`hidden md:flex flex-col gap-[20px]`}>
        <div className={`flex flex-row gap-[16px] overflow-visible animate-mediaArtAnimation`}>
          {Array.from(Array(30).keys()).map((k) => {
            return <TempPartnerCard key={k} />
          })}
        </div>
        <div className={`flex flex-row gap-[16px] overflow-visible animate-mediaArtAnimationReverse`}>
          {Array.from(Array(30).keys()).map((k) => {
            return <TempPartnerCard key={k} />
          })}
        </div>
        <div className={`flex flex-row gap-[16px] overflow-visible animate-mediaArtAnimation`}>
          {Array.from(Array(30).keys()).map((k) => {
            return <TempPartnerCard key={k} />
          })}
        </div>
      </div>
    </div>
  )
}

const TempPartnerCard = () => {
  return <img className={`w-[137px] md:w-[234px] xl:w-[318px] xl:h-[120px]`} src={TempPartner} alt={`temp_partner`} />
}

export default Partners
