import InstagramIcon from '../../assets/icons/instagram.svg'
import YoutubeIcon from '../../assets/icons/youtube.svg'

const SnsCopyright = () => {
  // TODO: 실제 링크 추가되면 onClickSns 및 주석 제거
  // const onClickExternalUrl = (url: string) => {
  //   window.open(url)
  // }
  const onClickSns = () => {
    alert('준비중입니다.')
  }

  return (
    <div className={`flex flex-col justify-center items-start`}>
      <div className={`flex flex-row justify-start items-center`}>
        <img className={`cursor-pointer`} alt={`instagram`} src={InstagramIcon} onClick={onClickSns} />
        <img className={`cursor-pointer`} alt={`youtube`} src={YoutubeIcon} onClick={onClickSns} />
      </div>
      <div className={`text-gray300`}>{`© Viasonic Coporation. All rights reserved`}</div>
    </div>
  )
}

export default SnsCopyright
