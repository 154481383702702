import { PaletteOptions } from '@mui/material'
import colors from './colors'

const Palette = (): PaletteOptions => {
  return {
    primary: {
      main: colors.blue700,
    },
  }
}
export default Palette
