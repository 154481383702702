import SectionLayout from '../SectionLayout'
import React, { useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import PrevIcon from '../../assets/icons/prev.svg'
import NextIcon from '../../assets/icons/next.svg'
import AboutUsMobile from './AboutUsMobile'
import HistoryMobile from './HistoryMobile'
import AboutUsTabletDesktop from './AboutUsTabletDesktop'
import HistoryTabletDesktop from './HistoryTabletDesktop'
import Honors from './Honors'
import Partners from './Partners'

const Introduce = () => {
  const TabNames = ['Viasonic', 'History', 'Honors', 'Partners']
  const [selected, setSelected] = useState(0)
  const [swiper, setSwiper] = useState<SwiperCore>()
  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(true)

  return (
    <SectionLayout className={`pt-[36px] pb-[30px] md:pt-[75px] md:pb-[30px] xl:pt-[125px] xl:pb-[30px] bg-white`}>
      <div className={`relative w-full flex flex-col gap-[40px] justify-center items-center md:h-[676px] xl:h-[969px]`}>
        <div
          className={`absolute w-full xl:max-w-[1680px] z-10 h-[60px] bottom-[60%] mx-auto hidden md:block px-[24px]`}
        >
          <img
            className={(hasPrev ? `float-left` : `hidden`) + ` max-md:hidden cursor-pointer w-[60px]`}
            alt={'prev'}
            src={PrevIcon}
            onClick={() => swiper.slidePrev()}
          />
          <img
            className={(hasNext ? `float-right` : `hidden`) + ` max-md:hidden cursor-pointer w-[60px]`}
            alt={'next'}
            src={NextIcon}
            onClick={() => swiper.slideNext()}
          />
        </div>
        <div
          className={`w-full flex flex-row justify-start items-center gap-[20px] prose-p3 font-normal text-gray500 md:hidden px-[20px]`}
        >
          {TabNames.map((name, i) => {
            return (
              <div
                key={i}
                className={
                  (selected === i ? `font-semibold text-blue700 border-b border-blue700` : ``) +
                  ` cursor-pointer h-[36px] flex justify-center items-center`
                }
                onClick={() => swiper.slideTo(i)}
              >
                <div>{name}</div>
              </div>
            )
          })}
        </div>
        <div className={`w-full`}>
          <Swiper
            spaceBetween={40}
            slidesPerView={1}
            onSlideChange={(swiper: SwiperCore) => {
              const slideIndex = swiper.activeIndex
              setSelected(slideIndex)
              if (slideIndex === 0) {
                setHasNext(true)
                setHasPrev(false)
              } else if (slideIndex === 3) {
                setHasNext(false)
                setHasPrev(true)
              } else {
                setHasNext(true)
                setHasPrev(true)
              }
            }}
            onSwiper={setSwiper}
          >
            <SwiperSlide className={`px-[20px]`}>
              <AboutUsMobile />
              <AboutUsTabletDesktop />
            </SwiperSlide>
            <SwiperSlide className={`px-[20px]`}>
              <HistoryMobile />
              <HistoryTabletDesktop />
            </SwiperSlide>
            <SwiperSlide className={`px-[20px]`}>
              <Honors />
            </SwiperSlide>
            <SwiperSlide className={`overflow-hidden`}>
              <Partners />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </SectionLayout>
  )
}

export default Introduce
