import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { initializeApp } from 'firebase/app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const firebaseConfig = {
  apiKey: 'AIzaSyAruWTiUx6CDodF4gu1T73UXXpq6q8EP8g',
  authDomain: 'viasonic-28b16.firebaseapp.com',
  projectId: 'viasonic-28b16',
  storageBucket: 'viasonic-28b16.appspot.com',
  messagingSenderId: '64717044183',
  appId: '1:64717044183:web:601e9ed6c52ec1b2eb3392',
  measurementId: 'G-2CZWY2P092',
}

if (typeof window !== 'undefined') {
  initializeApp(firebaseConfig)
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
