import { MutableRefObject, useEffect, useState } from 'react'

const TAB_NAMES = ['Viasonic', 'Product', 'Service', 'News', 'Contact']

const Navigation = ({ tabRef }: { tabRef: MutableRefObject<HTMLDivElement[]> }) => {
  const [currentTab, setCurrentTab] = useState<HTMLDivElement>()

  useEffect(() => {
    setCurrentTab(tabRef.current[0])
  }, [])

  const onClickTab = (index: number) => {
    setCurrentTab(tabRef.current[index])
    tabRef.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <div
      className={`max-md:hidden flex flex-row md:gap-[24px] xl:gap-[32px] md:prose-h8 xl:prose-h7-normal text-gray500`}
    >
      {TAB_NAMES.map((title, index) => {
        return (
          <div
            key={index}
            className={currentTab === tabRef.current[index] ? 'text-blue700 font-semibold ' : 'font-normal'}
            title={title}
            onClick={() => onClickTab(index)}
            style={{ cursor: 'pointer' }}
          >
            {title}
          </div>
        )
      })}
    </div>
  )
}

export default Navigation
