import AboutUs1 from '../../assets/images/introduce/viasonic/viasonic_1.svg'
import AboutUs2 from '../../assets/images/introduce/viasonic/viasonic_2.svg'
import React from 'react'

const AboutUsMobile = () => {
  return (
    <div className={`flex flex-col gap-[24px] justify-center items-start max-w-[335px] mx-auto md:hidden`}>
      <div className={`prose-h7 text-black`}>{`ViaSonic About us`}</div>
      <div className={`flex flex-col gap-[32px] justify-center items-start text-gray800`}>
        <div className={`flex flex-col gap-[20px] justify-center items-start`}>
          <div className={`prose-p3 whitespace-pre-line text-gray800`}>
            {`Sound envelops the subtle world around us.\nAt viasonic, we`}
            <span className={`font-semibold`}>{` delve`}</span>
            {` into this world of sound,`}
            <span className={`font-semibold`}>{` clarifying`}</span>
            {` the principles of hearing to enrich the sounds of daily life.`}
          </div>
          <img className={`w-full h-[152px] object-cover rounded-[12px]`} alt={`about_us_1`} src={AboutUs1} />
        </div>
        <div className={`flex flex-col gap-[20px] justify-center items-start`}>
          <div className={`prose-p3 whitespace-pre-line`}>
            {`At the forefront of auditory research, we explore the`}
            <span className={`font-semibold`}>{` direction and meaning of sound, overcome hearing`}</span>
            {` impairments,  and expand the possibilities of auditory experience.`}
          </div>
          <img className={`w-full h-[152px] object-cover rounded-[12px]`} alt={`about_us_2`} src={AboutUs2} />
        </div>
      </div>
    </div>
  )
}

// <div className={`flex flex-col gap-[24px] justify-center items-start md:hidden`}>
//   <div className={`prose-h7`}>{`ViaSonic About us`}</div>
//   <div className={`flex flex-col gap-[32px] justify-center items-start`}>
//     <div className={`flex flex-col gap-[20px] justify-center items-start`}>
//       <div className={`prose-p3 whitespace-pre-line`}>
//         {`Sound envelops the subtle world around us.\nAt viasonic, we`}
//         <span className={`font-semibold`}>{` delve`}</span>
//         {` into this world of sound,`}
//         <span className={`font-semibold`}>{` clarifying`}</span>
//         {` the principles of hearing to enrich the sounds of daily life.`}
//       </div>
//       <img className={`w-full h-[152px] object-cover rounded-[12px]`} alt={`about_us_1`} src={AboutUs1} />
//     </div>
//     <div className={`flex flex-col gap-[20px] justify-center items-start`}>
//       <div className={`prose-p3 whitespace-pre-line`}>
//         {`At the forefront of auditory research, we explore the`}
//         <span className={`font-semibold`}>{` direction and meaning of sound, overcome hearing`}</span>
//         {` impairments,  and expand the possibilities of auditory experience.`}
//       </div>
//       <img className={`w-full h-[152px] object-cover rounded-[12px]`} alt={`about_us_2`} src={AboutUs2} />
//     </div>
//   </div>
// </div>

export default AboutUsMobile
