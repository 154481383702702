import FOOTER from '../../data/footer'

const CompanyInformationMobile = () => {
  const onClickExternalUrl = (url: string) => window.open(url)

  return (
    <div
      className={`w-4/5 grid grid-cols-12 place-content-start place-items-start gap-[8px] justify-start items-start md:hidden`}
    >
      {FOOTER.info.map((info, i) => {
        return (
          <div
            key={i}
            className={
              (info.title === 'Tell' || info.title === 'Fax' ? `col-span-6` : `col-span-12`) +
              ` flex flex-row gap-[4px] justify-start items-start`
            }
          >
            <div className={`text-gray500`}>{info.title}</div>
            <div className={`text-gray500`}>{` :`}</div>
            <div className={``}>{info.content}</div>
            {info.title === 'Business Number' && (
              <div
                className={`cursor-pointer font-semibold`}
                onClick={() => onClickExternalUrl(FOOTER.url.businessInformation)}
              >
                사업자정보확인
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default CompanyInformationMobile
