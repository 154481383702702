import HistoryBackground from '../../assets/images/introduce/history/history.svg'
import React, { useEffect, useState } from 'react'

const HistoryMobile = () => {
  const [selected, setSelected] = useState(0)

  return (
    <div className={`relative h-[652px] max-w-[335px] md:hidden mx-auto`}>
      <div className={`relative flex flex-col justify-center items-start px-[24px] py-[16px]`}>
        <img
          className={`absolute z-10 top-0 left-0 rounded-[10px]`}
          alt={`history_background`}
          src={HistoryBackground}
        />
        <div className={`flex flex-col gap-[74px] justify-center items-start`}>
          <div className={`prose-h7 z-20 text-white`}>{`History`}</div>
          <div className={`flex z-20 flex-col gap-[11px] justify-center items-start prose-h8 text-white`}>
            {[`2023`, `2022`, `~2021`].map((year, i) => {
              return (
                <div
                  key={i}
                  className={(selected === i ? `opacity-100` : `opacity-50`) + ` cursor-pointer`}
                  onClick={() => setSelected(i)}
                >
                  {year}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={`absolute bottom-0 right-0 z-30 w-[241px] h-[412px] p-1`}>
        <div
          className={
            `w-full h-full p-[24px] flex flex-col gap-[16px] justify-start items-start bg-white` +
            ` rounded-[8px] shadow-[0px_0px_4px_0px_rgba(0,0,0,0.12)]`
          }
        >
          <CardRenderer selected={selected} />
        </div>
      </div>
    </div>
  )
}

const CardRenderer = ({ selected }: { selected: number }) => {
  const [fade, setFade] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setFade('end')
    }, 100)

    return () => {
      setFade('')
    }
  }, [selected])

  return (
    <div className={'start ' + fade}>
      {[<Card2023 key={0} />, <Card2022 key={1} />, <Card2021 key={2} />][selected]}
    </div>
  )
}

const Card2023 = () => {
  return (
    <>
      <div className={`prose-h8 text-blue700 mb-[16px]`}>{`2023`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`November`}</div>
          <ul className={`list-disc list-inside`}>
            <li
              className={`text-gray500`}
            >{`Selected for the Ministry of SMEs and Startups Internal Corporate Venture Fostering Program`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`October`}</div>
          <ul className={`list-disc list-inside`}>
            <li
              className={`text-gray500`}
            >{`Selected for the Korea Expressway Corporation Internal Corporate Venture (5th Generation)`}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

const Card2022 = () => {
  return (
    <>
      <div className={`prose-h8 mb-[16px]`}>{`2022`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`June 2022`}</div>
          <ul className={`list-disc list-inside`}>
            <li
              className={`text-gray500`}
            >{`Awarded the Korea Expressway Corporation Excellent Research Project Commendation`}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

const Card2021 = () => {
  return (
    <>
      <div className={`prose-h8 mb-[16px]`}>{`~2021`}</div>
      <div className={`flex flex-col gap-[12px]`}>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`November 2016`}</div>
          <ul className={`list-disc list-inside`}>
            <li
              className={`text-gray500`}
            >{`Development of a road traffic noise acoustic power calculation program`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`November 2015`}</div>
          <ul className={`list-disc list-inside`}>
            <li className={`text-gray500`}>{`Development of a 3D noise prediction program for highways`}</li>
          </ul>
        </div>
        <div className={`flex flex-col gap-[4px] justify-center items-start prose-p4`}>
          <div className={`font-medium text-black`}>{`June 2015`}</div>
          <ul className={`list-disc list-inside`}>
            <li className={`text-gray500`}>{`Awarded the Minister of Environment Commendation on Environment Day.`}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HistoryMobile
