import React, { useState } from 'react'
import News_1 from '../assets/images/news/news_1.png'
import News_2 from '../assets/images/news/news_2.png'
import News_3 from '../assets/images/news/news_3.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Box } from '@mui/material'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { Swiper as SwiperType } from 'swiper'
import styled from '@emotion/styled'
import Prev from '../assets/icons/prev.svg'
import Next from '../assets/icons/next.svg'

const SwiperWrapper = styled(Box)({
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '32px',
  paddingBottom: '35px',
  backgroundColor: '#D5DEFF',
  '& .swiper-slide': {
    width: '100%',
    height: '100%',
  },
  '& .swiper-slide img': {
    objectFit: 'cover',
    width: '100%',
    height: '324px',
    borderRadius: '16px',
  },
  '& :nth-of-type(2) .swiper-slide': {
    marginTop: '12px',
  },
  '& :nth-of-type(2) .swiper-slide img': {
    objectFit: 'cover',
    width: '100%',
    height: '89px',
    borderRadius: '8px',
  },
  '& :nth-of-type(2) .swiper-slide-thumb-active': {
    borderRadius: '8px',
    padding: '6px',
    backgroundColor: 'rgba(255, 255, 255, 0.96)',
    height: '89px',
  },
  '& :nth-of-type(2) .swiper-slide-thumb-active img': {
    borderRadius: '8px',
    height: '77px',
  },
})

const ArrowContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  height: '44px',

  '@media (min-width: 1280px)': {
    height: '52px',
  },

  '.swiper-button-prev, .swiper-button-next': {
    position: 'unset',
    width: '44px',
    height: '44px',
    marginTop: '0',

    '@media (min-width: 1280px)': {
      width: '52px',
      height: '52px',
    },
  },
}

const newsItems = [
  {
    id: 1,
    src: News_1,
    content:
      'Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news',
    title: 'news title 1',
    date: '2024.01.21',
  },
  {
    id: 2,
    src: News_2,
    content:
      'Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news',
    title: 'news title 2',
    date: '2024.01.22',
  },
  {
    id: 3,
    src: News_3,
    content:
      'Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news Please write about news',
    title: 'news title 3',
    date: '2024.01.23',
  },
]

const NewsSection = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)
  const [moreContentNumber, setMoreContentNumber] = useState<number>(0)
  const onClickMore = (id: number) => {
    setMoreContentNumber(id)
  }
  return (
    <>
      <div className={'md:hidden'}>
        <div className={'px-[20px] py-[28px] flex flex-col gap-[12px] bg-[#F2F5FF]'}>
          <p className={'text-[#000AFF] text-[16px] font-[500] leading-[20px]'}>Via News</p>
          <div className={'flex flex-col gap-[4px]'}>
            <p className={'text-[20px] font-[600] leading-[24px] tracking-[0.5px]'}>News</p>
            <p className={'text-[16px] font-[400] leading-[22px]'}>
              Keep abreast of the latest industry trends and ViaSonic&apos;s news, bringing you the forefront of sound
              technology developments.
            </p>
          </div>
        </div>
        <SwiperWrapper>
          <Swiper
            spaceBetween={20}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            modules={[FreeMode, Navigation, Thumbs]}
            onSlideChange={() => setMoreContentNumber(0)}
          >
            {newsItems.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={'flex flex-col gap-[12px]'}>
                  <img src={item.src} alt={`new ${item.id}`} />
                  <div className={'flex flex-col gap-[4px]'}>
                    <p className={'text-[16px] font-[500] leading-[20px] text-[#252323]'}>{item.title}</p>
                    <p
                      className={`text-[16px] font-[400] leading-[18px] text-[#505050] ${item.id === moreContentNumber ? '' : 'line-clamp-2'}`}
                    >
                      {item.content}
                    </p>
                    <p className={'text-[12px] font-[400] leading-[18px] text-[#A7A7A7]'}>{item.date}</p>
                    {item.id === moreContentNumber ? (
                      <p
                        className={'text-[14px] font-[400] leading-[18px] text-[#8B8B8B] cursor-pointer'}
                        onClick={() => onClickMore(0)}
                      >
                        Fold
                      </p>
                    ) : (
                      <p
                        className={'text-[14px] font-[400] leading-[18px] text-[#8B8B8B] cursor-pointer'}
                        onClick={() => onClickMore(item.id)}
                      >
                        More
                      </p>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={11}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            {newsItems.map((image) => (
              <SwiperSlide key={image.id}>
                <img src={image.src} alt={`new ${image.id}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
      </div>
      <div className={'hidden md:block bg-[#F4F7FF] pt-[83px] pb-[84px]'}>
        <div className={'max-w-[1197px] ml-[80px] xl:mx-auto'}>
          <div className={'flex flex-col gap-[16px]'}>
            <p className={'text-[#000AFF] text-[18px] xl:text-[20px] font-[600] leading-[24px]'}>Via News</p>
            <div className={'flex flex-col gap-[8px]'}>
              <p className={'text-[32px] font-[700] leading-[38px] tracking-[0.5px] xl:text-[40px] xl:leading-[50px]'}>
                News
              </p>
              <div className={'flex items-center justify-between mr-[30px] mb-[60px] xl:mb-[40px] relative'}>
                <p className={'text-[18px] font-[400] leading-[24px] xl:text-[24px] xl:font-[500] xl:leading-[28px]'}>
                  Keep abreast of the latest industry trends and ViaSonic&apos;s news,
                  <br />
                  bringing you the forefront of sound technology developments.
                </p>
                <Box sx={ArrowContainer}>
                  <img src={Prev} alt={'prev'} className={'swiper-button-prev'} />
                  <img src={Next} alt={'next'} className={'swiper-button-next'} />
                </Box>
              </div>
            </div>
          </div>
          <Box
            sx={{
              '.swiper-slide-active': {
                width: '39.84vw !important',
                '@media (min-width: 1280px)': {
                  width: '755px !important',
                },
              },
            }}
          >
            <Swiper
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[Navigation]}
              style={{ overflow: 'visible' }}
              loop={true}
              breakpoints={{
                768: {
                  slidesPerView: 3.5,
                },
                1280: {
                  slidesPerView: 2.5,
                },
              }}
            >
              {newsItems.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={`flex flex-col gap-[24px]`}>
                    <Box
                      className={'h-[219px] xl:h-[387px] rounded-[16px]'}
                      sx={{
                        background: `url('${item.src}') no-repeat center / cover`,
                      }}
                    />
                    <div className={'flex flex-col gap-[4px]'}>
                      <p className={'text-[24px] font-[700] leading-[28px] tracking-[0.5px] text-[#252323]'}>
                        {item.title}
                      </p>
                      <p className={'text-[18px] font-[400] leading-[26px] text-[#505050] line-clamp-4'}>
                        {item.content}
                      </p>
                      <p className={'text-[18px] font-[400] leading-[26px] text-[#A7A7A7] mt-[16px] xl:mt-[20px]'}>
                        {item.date}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              {/* news 아이템이 6개 이하일 경우 두번 출력해야 함 */}
              {newsItems.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={`flex flex-col gap-[24px]`}>
                    <Box
                      className={'h-[219px] xl:h-[387px] rounded-[16px]'}
                      sx={{
                        background: `url('${item.src}') no-repeat center / cover`,
                      }}
                    />
                    <div className={'flex flex-col gap-[4px]'}>
                      <p className={'text-[24px] font-[700] leading-[28px] tracking-[0.5px] text-[#252323]'}>
                        {item.title}
                      </p>
                      <p className={'text-[18px] font-[400] leading-[26px] text-[#505050] line-clamp-4'}>
                        {item.content}
                      </p>
                      <p className={'text-[18px] font-[400] leading-[26px] text-[#A7A7A7] mt-[16px] xl:mt-[20px]'}>
                        {item.date}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </div>
      </div>
    </>
  )
}

export default NewsSection
