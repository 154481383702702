import LogoBrandHorizontal from '../../assets/logo_brand_horizontal.svg'
import { ReactNode } from 'react'

const Header = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div
        className={
          'fixed z-10 w-full mx-auto h-[64px] md:h-[92px] xl:h-[120px] p-[24px] md:fixed' +
          ' flex justify-center items-center' +
          ' border-b-2 border-gray200 bg-white z-50'
        }
      >
        <div className={`w-full max-w-[1560px] flex flex-row justify-between items-center`}>
          <img
            className={`w-[120px] md:w-[180px] xl:w-[264px]`}
            alt={`logo_brand_horizontal`}
            src={LogoBrandHorizontal}
          />
          {children}
        </div>
      </div>
      <div className={`w-full h-[64px] md:h-[92px] xl:h-[120px]`}></div>
    </>
  )
}

export default Header
