import { Theme } from '@emotion/react'
import { createTheme, PaletteOptions } from '@mui/material'
import React, { useMemo, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import Palette from './palette'
import AppLayout from './components/AppLayout'
import Header from './components/header/Header'
import Navigation from './components/header/Navigation'
import Hamburger from './components/header/Hamburger'
import BannerSection from './components/BannerSection'
import Footer from './components/footer/Footer'
import ServiceSection from './components/ServiceSection'
import ContactSection from './components/ContactSection'
import ScrollToTopButton from './components/ScrollToTopButton'
import Introduce from './components/introduce/Introduce'
import typography from './typography'
import NewsSection from './components/NewsSection'
import ProductSection from './components/product/ProductSection'

function App() {
  const palette: PaletteOptions = useMemo<PaletteOptions>(() => Palette(), [])
  const theme: Theme = useMemo(() => createTheme({ ...palette, typography }), [palette])
  const ref = useRef<HTMLDivElement[]>([] as HTMLDivElement[])

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <Header>
          <Hamburger tabRef={ref} />
          <Navigation tabRef={ref} />
        </Header>
        <BannerSection />
        <div className={'w-full'} ref={(el) => (ref.current[0] = el as HTMLDivElement)}>
          <Introduce />
        </div>
        <div className={'w-full bg-blue50'} ref={(el) => (ref.current[1] = el as HTMLDivElement)}>
          <ProductSection />
        </div>
        <div className={'w-full bg-white'} ref={(el) => (ref.current[2] = el as HTMLDivElement)}>
          <ServiceSection />
        </div>
        <div className={'w-full bg-white'} ref={(el) => (ref.current[3] = el as HTMLDivElement)}>
          <NewsSection />
        </div>
        <div
          className={'w-full flex justify-center items-center'}
          ref={(el) => (ref.current[4] = el as HTMLDivElement)}
        >
          <ContactSection />
        </div>
        <Footer />
        <ScrollToTopButton />
      </AppLayout>
    </ThemeProvider>
  )
}

export default App
