import { ImageCardData } from '../interface/imageCardData'

const ImageCardFeatureTabletDesktop = ({ feature }: { feature: ImageCardData }) => {
  return (
    <div className={`w-full flex flex-col gap-[16px] justify-start items-start`}>
      <div className={`w-full ` + feature.heightClassNameTabletDesktop}>
        <img className={`h-full rounded-[16px] object-cover`} alt={`feature_image`} src={feature.imageSrc} />
      </div>
      <div className={`flex flex-col gap-[12px] justify-center items-start`}>
        <div className={`prose-h8 xl:prose-h7 text-black whitespace-pre-line hidden md:max-xl:block`}>
          {feature.title.tablet}
        </div>
        <div className={`prose-h8 xl:prose-h7 text-black whitespace-pre-line hidden xl:block`}>
          {feature.title.desktop}
        </div>
        <div className={`prose-p3 text-gray800 whitespace-pre-line hidden md:max-xl:block`}>
          {feature.content.tablet}
        </div>
        <div className={`prose-p3 text-gray800 whitespace-pre-line hidden xl:block`}>{feature.content.desktop}</div>
      </div>
    </div>
  )
}

export default ImageCardFeatureTabletDesktop
