function split<T>(array: T[], chunk: number): T[][] {
  const result: T[][] = []

  for (let i = 0; i < array.length; i += chunk) {
    let tempArray: T[] = []

    tempArray = array.slice(i, i + chunk)
    result.push(tempArray)
  }

  return result
}

export default split
