import ContactMobile from '../assets/images/contact/M_contact.png'
import ContactTablet from '../assets/images/contact/T_contact.png'
import ContactDesktop from '../assets/images/contact/W_contact.png'
import { Box } from '@mui/material'

const ContactSection = () => {
  const handleOnClickContact = () => {
    window.open('mailto:info@viasonic.co.kr')
  }

  return (
    <>
      <div
        className="w-full md:hidden flex flex-col justify-center items-center rounded-xl px-[28px] py-[79px] my-[36px] mx-[44px]"
        style={{ backgroundImage: `url(${ContactMobile})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <div className="w-full flex-col justify-start items-center gap-7 inline-flex">
          <div className="flex-col justify-start items-center gap-3 flex">
            <div className="text-center text-white text-[28px] font-bold leading-loose tracking-wide">Contact</div>
            <div className=" text-center text-white text-base font-normal leading-tight">
              If you have any questions about Viasonic, please contact us.
            </div>
          </div>
          <Box
            className="h-11 px-5 py-3 bg-white rounded-xl justify-center items-center gap-2.5 inline-flex cursor-pointer"
            onClick={handleOnClickContact}
          >
            <div className="text-stone-800 text-base font-semibold leading-tight whitespace-nowrap">Contact us</div>
          </Box>
        </div>
      </div>
      <div
        className={
          'w-full hidden my-[100px] mx-[80px] flex-col justify-center items-center rounded-[20px] md:max-xl:block py-[79px] bg-cover bg-center'
        }
        style={{ backgroundImage: `url(${ContactTablet})` }}
      >
        <div className="w-full flex-col justify-start items-center gap-12 inline-flex">
          <div className="flex-col justify-start items-center gap-4 flex">
            <div className="text-center text-white text-[40px] font-bold leading-[50px] tracking-wide">Contact</div>
            <div className="text-center text-white text-lg font-normal leading-normal">
              If you have any questions, please contact us.
            </div>
          </div>
          <Box
            className="w-[137px] h-[50px] px-5 py-3 bg-white rounded-lg justify-center items-center gap-2.5 inline-flex cursor-pointer"
            onClick={handleOnClickContact}
          >
            <div className="text-stone-800 text-lg font-bold leading-relaxed whitespace-nowrap">Contact Us</div>
          </Box>
        </div>
      </div>
      <div
        className="w-[1197px] hidden my-[100px] mx-[80px] flex-col justify-center items-center rounded-[20px] xl:block py-[91px]"
        style={{ backgroundImage: `url(${ContactDesktop})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <div className="w-full flex-col justify-start items-center gap-12 inline-flex">
          <div className="flex-col justify-start items-center gap-4 flex">
            <div className="text-center text-white text-[40px] font-bold leading-[50px] tracking-wide">Contact</div>
            <div className="text-center text-white text-lg font-normal leading-normal">
              If you have any questions, please contact us.
            </div>
          </div>
          <Box
            className="w-[137px] h-[50px] px-5 py-3 bg-white rounded-lg justify-center items-center gap-2.5 inline-flex cursor-pointer"
            onClick={handleOnClickContact}
          >
            <div className="text-blue-700 text-lg font-bold leading-relaxed whitespace-nowrap">Contact Us</div>
          </Box>
        </div>
      </div>
    </>
  )
}
export default ContactSection
