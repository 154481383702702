import UniqueImage1 from '../assets/images/technology/unique/unique_1.png'
import UniqueImage2 from '../assets/images/technology/unique/unique_2.png'
import UniqueImage3 from '../assets/images/technology/unique/unique_3.png'
import UniqueImage4 from '../assets/images/technology/unique/unique_4.jpg'
import { ImageCardData } from '../interface/imageCardData'

const UNIQUE = [
  {
    imageSrc: UniqueImage1,
    heightClassNameTabletDesktop: `md:h-[120px] xl:h-[279px]`,
    title: {
      mobile: `User-Friendly Interface`,
      tablet: `User-Friendly Interface`,
      desktop: `User-Friendly Interface`,
    },
    content: {
      mobile: `ViaNoise is known for its intuitive user\ninterface, making it accessible and easy to use\nfor professionals of all skill levels.`,
      tablet: `ViaNoise is known for its intuitive user interface, making it accessible\nand easy to use for professionals of all skill levels.`,
      desktop: `ViaNoise is known for its intuitive user\ninterface, making it accessible and easy\nto use for professionals of all skill levels.`,
    },
  },
  {
    imageSrc: UniqueImage2,
    heightClassNameTabletDesktop: `md:h-[120px] xl:h-[279px]`,
    title: {
      mobile: `Seamless Cross-Platform\nIntegration`,
      tablet: `Seamless Cross-Platform Integration`,
      desktop: `Seamless Cross-\nPlatform Integration`,
    },
    content: {
      mobile: `The software stands out for its ability to\nintegrate effortlessly with a variety of\nplatforms and systems, enhancing its\nversatility in different projects.`,
      tablet: `The software stands out for its ability to integrate effortlessly with a variety\nof platforms and systems, enhancing its versatility in different projects.`,
      desktop: `The software stands out for its ability\nto integrate effortlessly with a variety of\nplatforms and systems, enhancing its\nversatility in different projects.`,
    },
  },
  {
    imageSrc: UniqueImage3,
    heightClassNameTabletDesktop: `md:h-[120px] xl:h-[279px]`,
    title: {
      mobile: `Continuous Innovation`,
      tablet: `Continuous Innovation`,
      desktop: `Continuous Innovation`,
    },
    content: {
      mobile: `ViaNoise is continuously updated, ensuring\nusers always have access to the latest\ntechnology.`,
      tablet: `ViaNoise is continuously updated, ensuring users always have access\nto the latest technology.`,
      desktop: `ViaNoise is continuously updated,\nensuring users always have access\nto the latest technology.`,
    },
  },
  {
    imageSrc: UniqueImage4,
    heightClassNameTabletDesktop: `md:h-[120px] xl:h-[279px]`,
    title: {
      mobile: `Active User Community`,
      tablet: `Active User Community`,
      desktop: `Active User Community`,
    },
    content: {
      mobile: `The software is supported by an active\ncommunity of users and experts, fostering\ncollaboration and shared learning within the field.`,
      tablet: `The software is supported by an active community of users and\nexperts, fostering collaboration and shared learning within the field.`,
      desktop: `The software is supported by an\nactive community of users and experts,\nfostering collaboration and shared\nlearning within the field.`,
    },
  },
] as ImageCardData[]

export default UNIQUE
