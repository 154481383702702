const colors = {
  blue700: '#0B15FF',
  blue50: '#F2F5FF',
  blueSub: '#7C86AA',

  gray800: '#505050',
  gray500: '#8B8B8B',
  gray400: '#A7A7A7',
  gray300: '#BFBFBF',
  gray200: '#DADADA',
  gray100: '#ECECEC',

  black: '#252323',
  white: '#ffffff',
}

export default colors
