import FEATURES from '../../data/features'
import ImageCardMobile from '../ImageCardMobile'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'

const FeaturesMobile = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>'
    },
  }

  return (
    <div className={`w-full md:hidden max-w-[335px] mx-auto`}>
      <SwiperWrapper>
        <Swiper pagination={pagination} modules={[Pagination]} allowTouchMove={false}>
          {FEATURES.map((feature, i) => {
            return (
              <SwiperSlide key={i}>
                <ImageCardMobile
                  title={feature.title.mobile}
                  imageSrc={feature.imageSrc}
                  content={feature.content.mobile}
                  index={i}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperWrapper>
    </div>
  )
}

const SwiperWrapper = styled(Box)({
  '& .swiper': {
    width: '100%',
    height: '100%',
  },

  '& .swiper-slide': {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .swiper-pagination': {
    position: 'unset',
    display: 'flex',
    justifyContent: 'center',
    gap: '28px',
    paddingTop: '40px',
    '@media (min-width: 768px)': {
      gap: '40px',
      paddingTop: '16px',
    },
    '@media (min-width: 1280px)': {
      paddingTop: '44px',
    },
  },

  '& .swiper-pagination-bullet': {
    width: '24px',
    height: '24px',
    color: '#A7A7A7',
    opacity: '1',
    background: 'none',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    '@media (min-width: 768px)': {
      fontSize: '20px',
      letterSpacing: '0.5px',
    },
  },

  '& .swiper-pagination-bullet-active': {
    color: '#0B15FF',
    background: '#fff',
  },
})

export default FeaturesMobile
