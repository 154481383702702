import React from 'react'
import AboutUs1 from '../../assets/images/introduce/viasonic/viasonic_1.svg'
import AboutUs2 from '../../assets/images/introduce/viasonic/viasonic_2.svg'

const AboutUsTabletDesktop = () => {
  return (
    <div className={`relative md:max-w-[864px] md:h-[631px] xl:max-w-[1197px] xl:h-[883px] mx-auto max-md:hidden`}>
      <div className={`flex flex-col gap-[20px] justify-center items-start max-w-[482px] xl:max-w-[693px]`}>
        <div className={`flex flex-col gap-[8px] justify-center items-start`}>
          <div className={`prose-p2 xl:prose-h8 font-semibold text-blue700`}>{`Viasonic`}</div>
          <div className={`prose-h5 xl:prose-h3 text-black`}>
            <span className={`xl:hidden`}>{`ViaSonic `}</span>
            {`About Us`}
          </div>
        </div>
        <div className={`prose-p2 xl:prose-h7-normal flex flex-col gap-[12px] text-gray800`}>
          <div className={`whitespace-pre-line`}>
            {`Sound envelops the subtle world around us.\nAt viasonic, we`}
            <span className={`font-semibold`}>{` delve`}</span>
            {` into this world of sound,`}
            <span className={`font-semibold`}>{` clarifying`}</span>
            {` the principles of hearing to enrich the sounds of daily life.`}
          </div>
          <div className={`whitespace-pre-line`}>
            {`At the forefront of auditory research, we explore the`}
            <span className={`font-semibold`}>{` direction and meaning of sound, overcome hearing`}</span>
            {` impairments,  and expand the possibilities of auditory experience.`}
          </div>
        </div>
      </div>
      <img
        className={`absolute top-0 right-0 md:w-[33vw] md:max-xl:max-w-[340px] h-[399px] xl:w-[446px] xl:h-[524px] object-cover rounded-[12px]`}
        alt={`about_us_1`}
        src={AboutUs1}
      />
      <img
        className={`absolute bottom-0 left-[70px] xl:left-[172px] w-[624px] h-[324px] xl:w-[819px] xl:h-[425px] object-cover rounded-[12px]`}
        alt={`about_us_2`}
        src={AboutUs2}
      />
    </div>
  )
}

export default AboutUsTabletDesktop
