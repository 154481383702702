import ViaNoise1 from '../../assets/images/technology/product/via_noise_1.png'
import ViaNoise2 from '../../assets/images/technology/product/via_noise_2.png'
import ViaNoise3 from '../../assets/images/technology/product/via_noise_3.png'

const ProductMobile = () => {
  return (
    <div className={`w-full flex flex-col gap-[24px] justify-center items-center max-w-[335px] mx-auto md:hidden`}>
      <div className={`w-full flex flex-col gap-[28px] justify-center items-start text-black`}>
        <div className={`w-full flex flex-col gap-[8px] justify-center items-start`}>
          <div className={`prose-h7`}>{`Welcome to ViaNoise`}</div>
          <div className={`prose-p3 whitespace-pre-line`}>{`Innovation at the\nForefront of Technology`}</div>
        </div>
        <div className={`w-full flex flex-col gap-[12px] justify-center items-center`}>
          {[ViaNoise1, ViaNoise2, ViaNoise3].map((src, i) => {
            return (
              <img
                key={i}
                className={`w-full rounded-[16px] h-[123px] object-cover px-[5px]`}
                alt={`via_noise`}
                src={src}
              />
            )
          })}
        </div>
      </div>
      <div className={`pb-[24px] prose-p3 text-gray800`}>
        {`We are dedicated to pushing the boundaries\nof technology and innovation. Our team of\ne0xperts is committed to developing cutting-\nedge solutions that address the challenges of\ntoday and shape the future.`}
      </div>
    </div>
  )
}

export default ProductMobile
