import ViaNoise1 from '../../assets/images/technology/product/via_noise_1.png'
import ViaNoise2 from '../../assets/images/technology/product/via_noise_2.png'
import ViaNoise3 from '../../assets/images/technology/product/via_noise_3.png'

const ProductTabletDesktop = () => {
  return (
    <div
      className={`w-full hidden md:flex flex-col justify-center items-center mx-auto max-w-[864px] xl:max-w-[1200px] `}
    >
      <div
        className={
          `w-full flex flex-col gap-[48px] justify-center items-start` +
          ` xl:flex-row xl:gap-[32px] xl:justify-start xl:items-center`
        }
      >
        <div className={`w-full flex-1 flex flex-col gap-[20px] justify-center items-start xl:gap-[32px] xl:w-[578px]`}>
          <div className={`flex flex-col gap-[8px] justify-center items-start xl:gap-[12px]`}>
            <div className={`prose-p2 font-semibold text-blue700 xl:prose-h8`}>{`Product`}</div>
            <div className={`text-black flex flex-col justify-center items-start`}>
              <div className={`prose-h5 xl:prose-h3`}>{`Welcome to ViaNoise`}</div>
              <div className={`h-[36px] flex justify-center items-center`}>
                <span className={`prose-h8 align-middle xl:prose-h6 xl:h-[42px] xl:tracking-normal`}>
                  {`: Innovation at the Forefront of Technology`}
                </span>
              </div>
            </div>
          </div>
          <div className={`prose-p2 text-gray800 whitespace-pre-line xl:hidden`}>
            {`We are dedicated to pushing the boundaries of technology and innovation.\nOur team of experts is committed to developing cutting-edge solutions that\naddress the challenges of today and shape the future.`}
          </div>
          <div className={`prose-p2 text-gray800 whitespace-pre-line md:max-xl:hidden`}>
            {`We are dedicated to pushing the boundaries of technology\nand innovation. Our team of experts is committed to\n`}
            <span className={`font-bold`}>{`developing cutting-edge solutions`}</span>
            {`that address the\nchallenges of today and shape the future.`}
          </div>
        </div>
        <div className={`w-full flex-1 flex flex-row gap-[20px] justify-center items-center xl:flex-col xl:gap-[12px]`}>
          <img
            className={`rounded-[16px] md:max-xl:w-[49vw] h-[348px] object-none xl:flex-1 xl:w-full xl:h-[392px] shadow`}
            alt={`via_noise`}
            src={ViaNoise1}
          />
          <div
            className={`w-full flex flex-col gap-[20px] justify-center items-center xl:flex-row xl:flex-1 xl:gap-[12px]`}
          >
            <img
              className={`rounded-[16px] w-full xl:w-[49%] h-[164px] object-cover xl:h-[314px] shadow`}
              alt={`via_noise`}
              src={ViaNoise2}
            />
            <img
              className={`rounded-[16px] w-full xl:w-[49%] h-[164px] object-cover xl:h-[314px] shadow`}
              alt={`via_noise`}
              src={ViaNoise3}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductTabletDesktop
