import React, { useEffect, useState } from 'react'
import UpArrow from '../assets/icons/to_top.svg'
import { Box } from '@mui/material'

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOnClickScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Box
      className={`${
        isVisible ? 'opacity-100 transition-opacity duration-300' : 'opacity-0 transition-opacity duration-300'
      } w-[60px] h-[60px] fixed z-10 bottom-11 right-6 md:max-xl:bottom-8 md:max-xl:right-[82px] xl:bottom-[52px] xl:right-[9.735%] cursor-pointer rounded-full`}
      onClick={handleOnClickScrollToTop}
    >
      <img alt={'scroll to top'} src={UpArrow} />
    </Box>
  )
}

export default ScrollToTopButton
