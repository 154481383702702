import React from 'react'
import SectionLayout from './SectionLayout'
import Service_1 from '../assets/images/service/service_1.jpg'
import Service_2 from '../assets/images/service/service_2.jpg'
import Service_3 from '../assets/images/service/service_3.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import LogoBrandHorizontal from '../assets/logo_brand_horizontal.svg'
import { Box } from '@mui/material'
import colors from '../colors'
import NextIcon from '../assets/icons/next.svg'
import PrevIcon from '../assets/icons/prev.svg'

const swiperContainterSx = {
  position: 'relative',
  width: '100%',
  '.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal': {
    bgcolor: '#D9D9D9',
    height: '2px',
    borderRadius: '4px',
    left: '20px',
    width: 'calc(100% - 20px * 2)',
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  '.swiper-scrollbar-drag': {
    bgcolor: colors.blue700,
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  '.service-arrow-left, .service-arrow-right': {
    display: 'none',
    cursor: 'pointer',
    width: '60px',
    '@media (min-width: 768px)': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      transform: 'translateY(-50%)',
    },
  },
  '.swiper-button-disabled': {
    display: 'none',
  },
}

interface ServiceItemProps {
  title: string
  subTitle: string
  description: string
  imageUrl: string
}

const ServiceItem = ({ title, subTitle, description, imageUrl }: ServiceItemProps) => {
  return (
    <div
      className={
        'w-full flex-col justify-start items-start gap-5 flex px-5 ' +
        'md:items-center md:gap-[20px] ' +
        'xl:gap-[40px]'
      }
    >
      <div className="justify-start items-start gap-3 flex flex-col md:items-center ">
        <div
          className={
            'text-blue-700 text-base font-medium leading-tight text-blue700 ' + 'md:text-[18px] ' + 'xl:text-[20px]'
          }
        >
          Via Service
        </div>
        <div
          className={
            'w-full min-h-[112px] text-2xl font-bold leading-7 tracking-wide ' +
            'md:text-[32px] md:min-h-[76px] md:max-w-[585px] md:text-center md:leading-[1.25] ' +
            'xl:text-[40px] xl:max-w-[733px]'
          }
        >
          {title}
        </div>
      </div>

      <div
        className={
          'w-full min-h-[552px] relative bg-white rounded-xl shadow flex flex-col ' +
          'md:shadow-none md:items-center md:min-h-0'
        }
      >
        <img
          className={
            'w-full h-[222px] rounded-tl-xl rounded-tr-xl object-cover object-center ' +
            'md:w-[450px] md:h-[301px] md:rounded-[16px] ' +
            'xl:w-[660px] xl:h-[441px]'
          }
          src={imageUrl}
          alt={title}
        />
        <div
          className={
            'w-full h-fit px-8 py-5 flex flex-col justify-start items-start gap-2 ' +
            'md:p-0 md:mt-[28px] md:max-w-[719px] md:text-center ' +
            'xl:mt-[40px] xl:gap-[12px]'
          }
        >
          <div
            className={
              'w-full text-xl font-semibold tracking-wide leading-7 ' + 'md:text-[24px] md:whitespace-pre-line'
            }
          >
            {subTitle}
          </div>
          <div className={'w-full text-gray800 font-normal leading-normal ' + 'md:text-[18px]'}>{description}</div>
        </div>
      </div>
    </div>
  )
}

const ServiceSection = () => {
  const handleOnClickContactUs = () => {
    window.open('mailto:info@viasonic.co.kr')
  }
  return (
    <SectionLayout
      className={
        'py-[42px] min-h-fit text-black flex flex-col max-w-[1680px] mx-auto ' +
        'md:py-[74px] ' +
        'xl:pt-[200px] xl:pb-[58px]'
      }
    >
      <Box sx={swiperContainterSx}>
        <Swiper
          className={'w-full h-[736px] relative bg-white ' + 'md:h-auto'}
          slidesPerView={1}
          modules={[Scrollbar, Navigation]}
          scrollbar={{ draggable: true, hide: false }}
          navigation={{
            nextEl: '.service-arrow-right',
            prevEl: '.service-arrow-left',
          }}
        >
          {/* Service Item 1 */}
          <SwiperSlide>
            <ServiceItem
              title="Engineering and R&D Services"
              subTitle={`Precision Engineering \nfor Optimal Solutions`}
              description="At viasonic, we bring precision and innovation to every engineering project, ensuring reliable solutions tailored to meet your most challenging requirements."
              imageUrl={Service_1}
            />
          </SwiperSlide>

          {/* Service Item 2 */}
          <SwiperSlide>
            <ServiceItem
              title="Engineering Software Development and System/Solution Implementation"
              subTitle={`Unmatched Expertise in Software \nDevelopment and System Integration`}
              description="Our expertise in engineering software development and system integration is unmatched. Viasonic delivers cutting-edge solutions that drive efficiency and operational excellence, transforming your complex challenges into streamlined processes."
              imageUrl={Service_2}
            />
          </SwiperSlide>

          {/* Service Item 3 */}
          <SwiperSlide>
            <ServiceItem
              title="Development of Measurement-Related Hardware"
              subTitle={`Innovating Precision in \nMeasurement Hardware`}
              description="Viasonic specializes in developing state-of-the-art measurement hardware. We offer advanced, accurate tools designed to elevate your data collection and analysis capabilities, ensuring precision and reliability in every measurement."
              imageUrl={Service_3}
            />
          </SwiperSlide>

          {/* Last Section */}
          <SwiperSlide>
            <div
              className={
                'w-full h-full flex flex-col justify-center items-center ' + 'md:min-h-[620px] ' + 'xl:min-h-[807px]'
              }
            >
              <img className={`w-[211px] ` + 'md:w-[264px] '} alt={`logo_brand_horizontal`} src={LogoBrandHorizontal} />
              <div
                className={
                  'text-center text-gray800 text-xl font-semibold leading-normal tracking-wide pt-5 pb-8 ' +
                  'md:text-[28px] md:leading-[1.1] md:font-[500] md:pt-[28px] md:pb-[48px] ' +
                  'xl:text-[36px] md:pt-[43px] md:pb-[80px] '
                }
              >
                If you have any questions,
                <br />
                please contact us.
              </div>
              <div
                className={
                  'w-[161px] h-[54px] py-3 bg-blue700 rounded-2xl justify-center items-center gap-2.5 inline-flex cursor-pointer ' +
                  'md:w-[199px] md:h-[64px] md:py-[20px] ' +
                  'xl:w-[289px] xl:h-[78px]'
                }
                onClick={handleOnClickContactUs}
              >
                <div className={'text-white text-base font-semibold leading-snug ' + 'xl:text-[32px] xl:font-[500]'}>
                  Contact us
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <Box>
          <img className="service-arrow-left left-0 md:left-[24px]" src={PrevIcon} alt="previous slide" />
          <img className="service-arrow-right right-0 md:right-[24px]" src={NextIcon} alt="next slide" />
        </Box>
      </Box>
    </SectionLayout>
  )
}

export default ServiceSection
